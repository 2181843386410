import { all, fork, put, call, takeEvery, select } from 'redux-saga/effects';
import { Token } from '../token/types';
import {
	ProcessToken,
	ProcessTokenFailed,
	ProcessTokenSuceeded,
} from '../token/actions';
import {
	UpdateUser,
	UpdateUserRequest,
	ProgressiveProfilingSucceded,
	FetchUser,
} from './actions';
import { AppState } from '../index';

import {
	getUserProfileData,
	UserApiModel,
	updateUserProfileData,
} from '../../api/userProfileDataApi';
import { getType } from 'typesafe-actions';
import {
	startSubmit,
	stopSubmit,
	setSubmitSucceeded,
	setSubmitFailed,
} from 'redux-form';
import settings from '../../config/settings';
import { ProfileActionTypes } from './types';
import Logger from '../../components/Logger/Logger';
import { FormLoaded, LoadingErrorAction } from '../loading/actions';
import { getCurrentCulture } from '../../utils/culture';
import { Culture, Cultures } from '../culture/types';
import { CultureChange } from '../culture/actions'
import { GetLegalInfoData } from './../legalInfo/actions';
import { getApplicationData, ApplicationApiModel } from './../../api/applicationDataApi';
import { FetchData } from './../application/actions';
import history from '../../history'

export function* getUserProfileDataAction(
	action: ProcessTokenFailed | ProcessTokenSuceeded
) {
	try {
		const token: Token = yield select((state: AppState) => state.token);

		if (
			action.type === getType(ProcessToken.failure) ||
			!token ||
			!token.accessToken
		) {
			const error = new Error('Token is not valid to retrieve user');
			yield put(FetchUser.failure(error));
			yield put(LoadingErrorAction(error));
			return;
		}

		const cultures: Cultures = yield select(
			(state: AppState) => state.cultures
		);

		let culture = cultures.currentCulture || 'en';

		const response: UserApiModel = yield call(
			getUserProfileData,
			token.accessToken,
			token.sub,
			token.clientId,
			culture
		);

		culture = getCurrentCulture(cultures.cultures, response);

		// If we have a culture
		if (culture) {
			yield put(CultureChange(culture));
		
			// Get application data to get lastest terms links
			const resp: ApplicationApiModel = yield call(
				getApplicationData,
				token.accessToken,
				token.clientId,
				culture
			);
			
			// Add application data to state
			yield put(FetchData.success(resp));
		}
		
		// Get legal info
		yield put(GetLegalInfoData());

		yield put(FetchUser.success(response));
	} catch (error: any) {
		yield put(LoadingErrorAction(error));
		yield put(FetchUser.failure(error));
	}
}

export function* updateUserProfileDataAction(action: UpdateUserRequest) {
	const FormId = 'DynamicForm';
	const { profile, navigate } = action.payload;
	try {
		yield put(startSubmit(FormId));

		const token: Token = yield select((state: AppState) => state.token);
		
		const cultures: Culture[] = yield select(
			(state: AppState) => state.cultures.cultures
		);

		const culture = getCurrentCulture(cultures);

		let userData = {};

		// We should only send keys having a value, this both for required and optional fields.
		for (const [key, value] of Object.entries(profile)) {
			if (!!value) {
				userData = {...userData, [key]: value}
			}
		}

		yield call(
			updateUserProfileData,
			token.accessToken,
			token.sub,
			userData,
			culture
		);

		yield put(UpdateUser.success(profile));
		yield put(stopSubmit(FormId));
		yield put(setSubmitSucceeded(FormId));
	} catch (error: any) {
		const ErrorLogger = new Logger();
		ErrorLogger.error('Error updating user profile', error);
		yield put(UpdateUser.failure(error));
		yield put(LoadingErrorAction({ message: error.message, response: error }));
		yield put(FormLoaded());
		yield put(stopSubmit(FormId));
		yield put(setSubmitFailed(FormId));
	}
}

export function* redirectToAuth(_action: ProgressiveProfilingSucceded) {
	try {
		const token: Token = yield select((state: AppState) => state.token);
		const appState = new URLSearchParams(window.location.search).get('state');
		if (!appState) {
			throw new Error('State should be set in query param');
		}
		const hostname = new URLSearchParams(window.location.search).get('hostname');
		const newSearchParams = new URLSearchParams();
		if (token && token.accessToken) {
			newSearchParams.set('token', token.accessToken);
		}
		newSearchParams.set('state', appState);

		document.location.replace(`//${hostname || settings.auth0.domain}/continue?${newSearchParams}`);
	} catch (error) {
		yield put(FormLoaded());
	}
}

function* watchGetUserProfileDataAction() {
	yield takeEvery(
		[getType(ProcessToken.success), getType(ProcessToken.failure)],
		getUserProfileDataAction
	);
}

function* watchUpdateUserProfileDataAction() {
	yield takeEvery(getType(UpdateUser.request), updateUserProfileDataAction);
}

function* watchProgressiveProfileSuccededAction() {
	yield takeEvery(
		ProfileActionTypes.PROGRESSIVE_PROFILING_SUCCEED,
		redirectToAuth
	);
}

function* profileSagas() {
	yield all([
		fork(watchGetUserProfileDataAction),
		fork(watchUpdateUserProfileDataAction),
		fork(watchProgressiveProfileSuccededAction),
	]);
}

export default profileSagas;
