import { LoadingActionTypes, LoadingError } from './types';
import { action } from 'typesafe-actions';

export interface Loading {
	type: LoadingActionTypes.LOADING;
}

export interface FormLoading {
	type: LoadingActionTypes.FORM_LOADING;
}

export interface FormLoaded {
	type: LoadingActionTypes.FORM_LOADED;
}

export interface Loaded {
	type: LoadingActionTypes.LOADED;
}

export interface LoadingErrorAction {
	type: LoadingActionTypes.LOADING_ERROR;
	payload?: LoadingError;
}

export const Loading = (): Loading => action(LoadingActionTypes.LOADING);

export const FormLoading = (): FormLoading =>
	action(LoadingActionTypes.FORM_LOADING);

export const FormLoaded = (): FormLoaded =>
	action(LoadingActionTypes.FORM_LOADED);

export const Loaded = (): Loaded => action(LoadingActionTypes.LOADED);

export const LoadingErrorAction = (
	payload?: LoadingError
): LoadingErrorAction => action(LoadingActionTypes.LOADING_ERROR, payload);

export type LoadingActionType =
	| Loading
	| Loaded
	| FormLoading
	| FormLoaded
	| LoadingErrorAction;
