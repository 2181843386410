import * as React from 'react';
import '../../stylesheets/stylesheet.scss';
import Header from '../Header/Header';
import Container from '@material-ui/core/Container';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import FormContainer from '../DynamicForm/FormContainer';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import MessageArea from '../MessageArea/MessageArea';
import { useTranslation } from 'react-i18next';
import {
	FormContainerStyle,
	DanfossBackground
} from '@danfoss/webex-ui/dist/mui';
import classnames from 'classnames';
import CookieInformation from './CookieInformation';
import { TelemetryService } from './AppInsights';
import GoogleAnalytics from './GoogleAnalytics';
import Footer from '../Footer/Footer';
import { createBrowserHistory } from 'history';
import parse from 'html-react-parser';
import { TermsAndConditions } from '../../api/applicationDataApi';

const useStyles = makeStyles(theme => ({
	containerOffset: {
		zIndex: 1100,
		flex: '1 0 auto',

		[theme.breakpoints.up('lg')]: {
			marginTop: 12,
			paddingBottom: 150,
		},
		[theme.breakpoints.down('md')]: {
			position: 'static',
			[theme.breakpoints.up('sm')]: {
				paddingBottom: 72,
				marginTop: 36
			}
		},
	},
	container: {
		flexGrow: 0,
		backgroundColor: 'white',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		[theme.breakpoints.up('sm')]: {
			borderRadius: 6
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(4.5),
			borderRadius: 0
		}
	},
	title: {
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '24px',
		},
		marginBottom: '24px',
		fontSize: '24px',
		fontWeight: 'bold'
	},
	margin: {
		marginTop: '27px !important'
	}
}));


const Main: React.FC<ReturnType<typeof mapStateToProps>> = props => {
	const {
		application: { application },
		latestDanfossDipTermsAndConditions,
		latestTermsAndConditions,
		latestPrivacyPolicy,
		latestPiplTerms,
		progressiveFields,
		tokens: { skip_accept_of_application_terms = false, skip_accept_of_dip_terms_and_privacy = false, skip_accept_of_pipl_terms = true } = {}
	} = props;
	const classes = FormContainerStyle();
	const { t } = useTranslation();
	const styles = useStyles();

	const history = createBrowserHistory();

	const showLegalInfo = (
		latestLegalInfoVersion: TermsAndConditions | undefined,
		acceptedInfoVersion: string | undefined,
		skip_accept: boolean,
		legalInfoDontNeeded?: boolean
	) => {
		if (legalInfoDontNeeded || skip_accept) {
			return false;
		}

		return (
			!acceptedInfoVersion ||
			(latestLegalInfoVersion &&
				latestLegalInfoVersion.latest_version !== acceptedInfoVersion)
		);
	};

	const isMyAccount = new URLSearchParams(window.location.search).get('isMyAccount') === 'true';

	const danfossDipTermsAnConditionsCheckVersionByUser =
		props.profile!.legal_info &&
		props.profile!.legal_info.accepted_dip_terms_and_conditions_version;

	const termsAnConditionsCheckVersionByUser =
		props.profile!.legal_info &&
		props.profile!.legal_info.application &&
		props.profile!.legal_info.application
			.accepted_terms_and_conditions_version;

	const privacyPolicyCheckedVersionByUser =
		props.profile!.legal_info &&
		props.profile!.legal_info.accepted_privacy_policy_version;

	const isPrivacyPolicyAccepted =
		props.profile!.legal_info &&
		props.profile!.legal_info.application &&
		props.profile!.legal_info.application.is_privacy_policy_accepted;

	const piplTermsCheckedVersionByUser =
		props.profile!.legal_info &&
		props.profile!.legal_info.accepted_pipl_terms_version;

	const termsAndConditionIsNeeded = showLegalInfo(
		latestTermsAndConditions,
		termsAnConditionsCheckVersionByUser,
		skip_accept_of_application_terms,
		isMyAccount);

	const danfossDipTermsAndConditionIsNeeded = showLegalInfo(
			latestDanfossDipTermsAndConditions,
			danfossDipTermsAnConditionsCheckVersionByUser,
			skip_accept_of_dip_terms_and_privacy
		);

	const privacyPolicyIsNeeded =
		(!isPrivacyPolicyAccepted && !skip_accept_of_application_terms) ||
		showLegalInfo(latestPrivacyPolicy, privacyPolicyCheckedVersionByUser,
			skip_accept_of_application_terms && skip_accept_of_dip_terms_and_privacy);

	const piplTermsIsNeeded = showLegalInfo(latestPiplTerms, piplTermsCheckedVersionByUser, skip_accept_of_pipl_terms);
	// @ts-ignore
	const checkboxesAreToBeShown = danfossDipTermsAndConditionIsNeeded || termsAndConditionIsNeeded || privacyPolicyIsNeeded || piplTermsIsNeeded;
	const fieldsAreToBeShown = progressiveFields?.fields?.length > 0 || false

	return (
		<React.Fragment>
			<TelemetryService history={history} />
			{application.app_type !== 'native' && (
				<CookieInformation />
			)}
			<GoogleAnalytics />
			<DanfossBackground />
			<Header />
			<Container
				className={classnames(classes.fullContainer, styles.containerOffset)}
			>
				<Grid container className={styles.container}>
					<Grid item xs={12} className={classes.borderHorizontal}>
						<Grid item className={classes.paper}>
							<Typography
								component="h2"
								variant="h5"
								className={styles.title}
							>
								{parse(t('reg_center.main_heading', {
									application: `<span translate="no">${application.name}</span>`,
								}))}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12} className={styles.margin}></Grid>
					<Grid item xs={12} md={6} className={classes.paper}>
						<MessageArea 
							appName={application.name} 
							checkboxesAreToBeShown={checkboxesAreToBeShown}
							fieldsAreToBeShown={fieldsAreToBeShown}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
						className={classnames(classes.borderVertical, classes.paper)}
					>
						<FormContainer 
							piplTermsIsNeeded={piplTermsIsNeeded??false}
							danfossDipTermsAndConditionIsNeeded={danfossDipTermsAndConditionIsNeeded??false}
							termsAndConditionIsNeeded={termsAndConditionIsNeeded??false}
							privacyPolicyIsNeeded={privacyPolicyIsNeeded??false}
						/>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</React.Fragment>
	);
};

const mapStateToProps = (appState: AppState) => ({
	profile: appState.profile,
	application: appState.application,
	latestDanfossDipTermsAndConditions: appState.legalInfo?.dip_terms_and_conditions,
	latestTermsAndConditions: appState.application.terms_and_conditions,
	latestPrivacyPolicy: appState.legalInfo?.privacy_policy,
	latestPiplTerms: appState.legalInfo?.pipl_terms,
	progressiveFields: appState.progressiveFields,
	tokens: appState.token,
});

export default connect(mapStateToProps)(Main);
