import React from 'react';
import { CheckboxField } from '../CheckboxField';
import { FieldUi } from '../../FieldUi';
import { TranslationUi, getTranslationUi, getTranslationText } from '../../../TranslationUi';

interface Label {
	name: string;
	url: string;
	appName: string;
}

export interface LegalInfoFieldUi extends FieldUi {
	labelLink: TranslationUi;
	labelLinkPlaceholder?: string,
	termsAndConditionIsNeeded?: boolean;
	danfossDipTermsAndConditionIsNeeded?: boolean;
	privacyPolicyIsNeeded?: boolean;
	piplTermsIsNeeded?: boolean;
	setLinkClicked?: any
}

export const LabelLink: React.FC<Label & LegalInfoFieldUi> = ({
	name,
	appName,
	url,
	label,
	labelLink,
	labelLinkPlaceholder,
	setLinkClicked
}) => {
	if ((label as TranslationUi).translationKey && labelLink.translationKey && labelLinkPlaceholder) { 
		const labelText = getTranslationText(label.translationKey);
        let firstPartOfText = labelText + ' ';
        let secondPartOfText = '';
        if (labelText.indexOf(`{{${labelLinkPlaceholder}}}`) !== -1) { 
            firstPartOfText = labelText.substring(0, labelText.indexOf(`{{${labelLinkPlaceholder}}}`));
            secondPartOfText = labelText.substring(labelText.indexOf(`{{${labelLinkPlaceholder}}}`) + `{{${labelLinkPlaceholder}}}`.length);
        }

		return (
			<React.Fragment>
				<span className="registration-center-link">
				{firstPartOfText}
                    <a onClick={() => setLinkClicked && setLinkClicked(true)} 
                        href={url}
                        target="_blank">
						{getTranslationUi(
							labelLink.translationKey,
							labelLink.defaultText
						)}
					</a>
                    {secondPartOfText}
				</span>	
			</React.Fragment>
		)
	}
	else {
		return (
			<React.Fragment>
				<span className="registration-center-link">
					{`${getTranslationUi(`user_profile.${name}.label`)} ${appName} `}
					<a href={url} rel="noopener noreferrer" target="_blank" onClick={() => setLinkClicked && setLinkClicked(true)}>
						{getTranslationUi(
							`user_profile.${name}.placeholder`,
							labelLink.defaultText
						)}
					</a>
				</span>
			</React.Fragment>
		);
	}
};

const LegalInfoField: React.FC<any> = props => {
	const { linkClicked, setLinkClicked, errorText, setErrorText, requireLinkClick } = props;
	const LabelComponent = LabelLink({ ...props, setLinkClicked });

	return CheckboxField({ ...props, label: LabelComponent, errorText, setErrorText, linkClicked, requireLinkClick });
};

export default LegalInfoField;
