import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GroupRenderer from '../Fields/GroupFields/GroupRenderer';
import { reduxForm, InjectedFormProps } from 'redux-form';
import LegalInfoField, {
	LegalInfoFieldUi,
} from '../Fields/FormFields/Components/LegalInfo/LegalInfo';
import { FieldUi } from '../Fields/FormFields/FieldUi';
import { Field, TermsAndConditions } from '../../api/applicationDataApi';
import { DynamicFormStyle, SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { useTranslation } from 'react-i18next';
import { getTranslationUi } from '../Fields/TranslationUi';
import settings from '../../config/settings';
import ToolTip from '../ToolTip/ToolTip';
import { Loading } from '../../store/loading/types';
import { makeStyles } from '@material-ui/core/styles';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import classnames from 'classnames';

const formId = 'DynamicForm';

const warn = (values: any, props: any) => {
	const { userRelatedFields } = props as { userRelatedFields: FieldUi[] };
	const warnings: { [key: string]: string } = {};
	const fieldsNameAndValidation: { [key: string]: string } = {};

	const requiredTextFields: FieldUi[] =
		userRelatedFields &&
		userRelatedFields.filter(
			(fields: FieldUi) =>
				fields.data_type === 'text' &&
				(fields.validation.max_len_fe || fields.validation.max_len)
		);

	requiredTextFields &&
		requiredTextFields.forEach(field => {
			fieldsNameAndValidation[field.name] =
				field.validation.max_len_fe || field.validation.max_len;
			if (
				values[field.name] &&
				values[field.name].length >= fieldsNameAndValidation[field.name]
			) {

				const errorLabel = `user_profile.${field.name}.error_label`;

				warnings[field.name] = `${getTranslationUi(
					'validation_messages.max_len',
					'',
					{
						property_name: getTranslationUi(
							errorLabel,
							field.label.defaultText
						),
						max_len: fieldsNameAndValidation[field.name],
					}
				)}`;
			}
		});

	return warnings;
};

const validate = (values: any, props: any) => {
	const errors: { [key: string]: any } = {};
	const fieldsObjectConfig = props.initialValues?.fieldsMetadata?.fieldsObject || {};

	const { userRelatedFields } = props as { userRelatedFields: FieldUi[] };
		
	if (userRelatedFields && fieldsObjectConfig) {
		const phoneFields = userRelatedFields.filter(
			field =>
			field.name === fieldsObjectConfig.phone_number.name ||
			field.name === fieldsObjectConfig.calling_code.name
		);

		if (phoneFields.length) {
			const callingCode = values[fieldsObjectConfig.calling_code.name];
			if (callingCode) {
				let callingCodeRegex = userRelatedFields.filter(
					field => field.name === fieldsObjectConfig.calling_code.name
				)[0].validation.regex;

				if (typeof callingCodeRegex === 'string') {
					callingCodeRegex = new RegExp(callingCodeRegex);
				}

				if (returnAsString(callingCode) !== '' && !callingCodeRegex.test(returnAsString(callingCode))) {

					const errorLabel = `user_profile.${fieldsObjectConfig.calling_code.name}.error_label`;

					errors[fieldsObjectConfig.calling_code.name] = getTranslationUi(
						'validation_messages.incorrect_character_used',

						`${getTranslationUi(
							errorLabel,
							fieldsObjectConfig.calling_code.label.defaultText
						)} has invalid characters`
					);
				} else {

					const callingCodeRegexMatch = returnAsString(callingCode).match(callingCodeRegex) || [];
					
					const phoneInput = values[fieldsObjectConfig.phone_number.name];
					if (phoneInput) {
						const phoneWithCallingCode = `+${callingCodeRegexMatch[3]} ${phoneInput}`;
						let phoneRegex = userRelatedFields.filter(
							field => field.name === fieldsObjectConfig.phone_number.name
						)[0].validation.regex;

						if (typeof phoneRegex === 'string') {
							phoneRegex = new RegExp(phoneRegex);
						}

						if (returnAsString(callingCode) !== '' && !phoneRegex.test(phoneWithCallingCode)) {

							const errorLabel = `user_profile.${fieldsObjectConfig.phone_number.name}.error_label`;

							errors[fieldsObjectConfig.phone_number.name] = [
								getTranslationUi(
									'validation_messages.incorrect_character_used',
									`${getTranslationUi(
										errorLabel,
										fieldsObjectConfig.phone_number.label.defaultText
									)} has invalid characters`
								),
								<ToolTip
									key={fieldsObjectConfig.phone_number.name}
									alwaysRed={true}
									toolTip={getTranslationUi(
										'tooltip_messages.phone_number_incorrect_character_used',
										`${getTranslationUi(
											errorLabel,
											fieldsObjectConfig.phone_number.label.defaultText
										)} can only contain numbers, dashes, spaces and paranthesis`
									)}
								/>,
							];
						}
					}
				}
			} else {
				if (values[fieldsObjectConfig.phone_number.name]) {

					const errorLabelPhoneNumber = `user_profile.${fieldsObjectConfig.phone_number.name}.error_label`
					const errorLabelCallingCode = `user_profile.${fieldsObjectConfig.calling_code.name}.error_label`

					errors[fieldsObjectConfig.phone_number.name] = getTranslationUi(
						'validation_messages.prop1_cannot_be_set_without_prop2',
						`${fieldsObjectConfig.calling_code.label.defaultText} should be set as well`,
						{
							property1_name: `${getTranslationUi(
								errorLabelPhoneNumber,
								fieldsObjectConfig.phone_number.label.defaultText
							)}`,
							property2_name: `${getTranslationUi(
								errorLabelCallingCode,
								fieldsObjectConfig.calling_code.label.defaultText
							)}`,
						}
					);
				}
			}
		}

		const embargoCountries = settings.embargoRestrictions.countries.split(',');
		const embargoRegions = settings.embargoRestrictions.regions.split(',');

		if (embargoCountries.includes(returnAsString(values.country) || "")) {
			errors[fieldsObjectConfig.country.name] = getTranslationUi('embargo.country_not_allowed');
		}

		if (embargoRegions.includes(returnAsString(values.state) || "")) {
			errors[fieldsObjectConfig.state.name] = getTranslationUi('embargo.region_not_allowed');
		}
	}
	return errors;
};

const returnAsString = (value: any) => {

	if (!value) return '';

	if (typeof value === 'string') {
		return value;
	} else {
		return value[0];
	}
}

export interface FormInitialValues {
	termAndConditions: TermsAndConditions;
	privacyPolicy: TermsAndConditions;
	legalInfo: {
		privacyPolicyData: LegalInfoFieldUi;
		termsAndConditionsData: LegalInfoFieldUi;
		danfossDipTermsAndConditionsData: LegalInfoFieldUi;
		piplTermsData: LegalInfoFieldUi;
	};
	country: string; // as default value
	applicationFields: Field[];
	requireLinkClick: boolean
}

interface AdditionalProps {
	formValues: any;
	userRelatedFields: FieldUi[];
	loading: Loading;
}

interface LegalInfoChecked {
	danfossDipTermsAndConditions: boolean | undefined;
	termsAndConditions: boolean | undefined;
	privacyPolicy: boolean | undefined;
	piplTerms: boolean | undefined;
}

const useStyles = makeStyles(theme => ({
	errorMessage: {
		color: colors.webexuiRedStrong,
		fontWeight: 'bold',
	},
	form: {
		marginTop: 4,
	},
	buttonsSection: {
		margin: '32px 0 10px 3px'
	},
	buttonMargin: {
		[theme.breakpoints.only('xs')]: {
			margin: '20px 0 0',
		},
		[theme.breakpoints.up('sm')]: {
			marginLeft: '15px',
		}
	},
	groupContainer: {
		padding: 0
	}
}));

const DynamicForm: React.FC<InjectedFormProps<FormInitialValues> &
	AdditionalProps> = props => {
	const {
		handleSubmit,
		initialValues,
		pristine,
		formValues,
		userRelatedFields,
		loading,
		valid
	} = props;
	const [danfossDipTermsAndConditionLinkClicked, setDanfossDipTermsAndConditionLinkClicked] = useState(false);
	const [termsAndConditionLinkClicked, setTermsAndConditionLinkClicked] = useState(false);
	const [privacyPolicyLinkClicked, setPrivacyPolicyLinkClicked] = useState(false);
	const [piplTermsLinkClicked, setPiplTermsLinkClicked] = useState(false);
	const [danfossDipTermsAndConditionErrorText, setDanfossDipTermsAndConditionErrorText] = useState("");
	const [termsAndConditionErrorText, setTermsAndConditionErrorText] = useState("");
	const [privacyPolicyErrorText, setPrivacyPolicyErrorText] = useState("");
	const [piplTermsErrorText, setPiplTermsErrorText] = useState("");
	
	const { legalInfo, applicationFields, requireLinkClick  } = initialValues;
	const filledFields: string[] | undefined = formValues && Object.keys(formValues).filter(
		(key: string) => { 
			// Special check for multiselect dropdowns where formValues contains an entry with empty value if none of the options are selected
			// In this case the submit button should be disabled
			if (formValues[key] && Array.isArray(formValues[key])) {
				return formValues[key].some((value: any) => !!value);
			}
			return true
		});

	const userRelatedFieldsUpdated = userRelatedFields.map(relField => {
		
		const appField = applicationFields?.find(field => field.name === relField.name);

		return {...relField, required: !appField?.is_optional || false}

	})

	const isTermsAndConditionNeeded = legalInfo!.termsAndConditionsData.termsAndConditionIsNeeded;
	const isPrivacyPolicyNeeded = legalInfo!.privacyPolicyData.privacyPolicyIsNeeded;
	const isDanfossDipTermsAndConditionNeeded = legalInfo!.danfossDipTermsAndConditionsData.danfossDipTermsAndConditionIsNeeded;
	const isPiplTermsNeeded = legalInfo && legalInfo.piplTermsData && legalInfo.piplTermsData.piplTermsIsNeeded;

	const isSubmitable = (
		userRelFields: FieldUi<string | boolean>[],
		legalInfoChecked: LegalInfoChecked,
		fieldsFilled: string[] = []
	) => {
		
		if (
			!legalInfoChecked ||
			(isTermsAndConditionNeeded && !legalInfoChecked.termsAndConditions)
		) {
			return false;
		}
		if (
			!legalInfoChecked ||
			(isPrivacyPolicyNeeded && !legalInfoChecked.privacyPolicy)
		) {
			return false;
		}
		if (
			!legalInfoChecked ||
			(isDanfossDipTermsAndConditionNeeded &&
				!legalInfoChecked.danfossDipTermsAndConditions)
		) {
			return false;
		}
		if (
			!legalInfoChecked ||
			(isPiplTermsNeeded &&
				!legalInfoChecked.piplTerms)
		) {
			return false;
		}

		for (const field of userRelFields) {

			const appField = (applicationFields as Field[]).find(afield => afield.name === field.name);

			if (!fieldsFilled.includes(field.name) && field.required && !(appField && appField.is_optional)) {
				return false;
			}
		}
		if (
			Object.keys(validate(fieldsFilled, { userRelFields })).length !== 0
		) {
			return false;
		}

		return true;
	};

	const classes = DynamicFormStyle();
	const localClasses = useStyles();
	const { t } = useTranslation();
	const cancel = () => {
		window.history.go(-1);
	};

	const LegalInfo: LegalInfoChecked = {
		danfossDipTermsAndConditions: formValues && formValues.danfoss_dip_terms_and_conditions,
		termsAndConditions: formValues && formValues.terms_and_conditions,
		privacyPolicy: formValues && formValues.privacy_policy,
		piplTerms: formValues && formValues.pipl_terms,
	};

	return (
		<Container>
			<CssBaseline />
			<div>
				{((userRelatedFields && userRelatedFields.length) ||
					isTermsAndConditionNeeded ||
					isPrivacyPolicyNeeded ||
					isDanfossDipTermsAndConditionNeeded ||
					isPiplTermsNeeded) && (
					<form
						className={classnames(classes.form, localClasses.form)}
						id="DynamicForm"
						onSubmit={handleSubmit}
					>
						<Grid container spacing={2} className={localClasses.groupContainer}>
							<GroupRenderer
								loading={loading.formLoading}
								fields={userRelatedFieldsUpdated || []}
							/>
							{isDanfossDipTermsAndConditionNeeded
								? LegalInfoField({
										...legalInfo!.danfossDipTermsAndConditionsData,
										loading: loading.formLoading,
										linkClicked: danfossDipTermsAndConditionLinkClicked,
										setLinkClicked: () => {setDanfossDipTermsAndConditionErrorText(''); setDanfossDipTermsAndConditionLinkClicked(true)},
										setErrorText: () => setDanfossDipTermsAndConditionErrorText(getTranslationUi("validation_messages.link_click_required") as string),
										errorText: danfossDipTermsAndConditionErrorText,
										requireLinkClick
								  })
								: null}
							{isTermsAndConditionNeeded
								? LegalInfoField({
										...legalInfo!.termsAndConditionsData,
										loading: loading.formLoading,
										linkClicked: termsAndConditionLinkClicked,
										setLinkClicked: () => {setTermsAndConditionErrorText(''); setTermsAndConditionLinkClicked(true)},
										setErrorText: () => setTermsAndConditionErrorText(getTranslationUi("validation_messages.link_click_required") as string),
										errorText: termsAndConditionErrorText,
										requireLinkClick
								  })
								: null}
							{isPrivacyPolicyNeeded
								? LegalInfoField({
										...legalInfo!.privacyPolicyData,
										loading: loading.formLoading,
										linkClicked: privacyPolicyLinkClicked,
										setLinkClicked: () => {setPrivacyPolicyErrorText('');setPrivacyPolicyLinkClicked(true)},
										setErrorText: () => setPrivacyPolicyErrorText(getTranslationUi("validation_messages.link_click_required") as string),
										errorText: privacyPolicyErrorText,
										requireLinkClick
								  })
								: null}
							{isPiplTermsNeeded
								? LegalInfoField({
										...legalInfo.piplTermsData,
										loading: loading.formLoading,
										linkClicked: piplTermsLinkClicked,
										setLinkClicked: () => {setPiplTermsErrorText(''); setPiplTermsLinkClicked(true)},
										setErrorText: () => setPiplTermsErrorText(getTranslationUi("validation_messages.link_click_required") as string),
										errorText: piplTermsErrorText,
										requireLinkClick
								  })
								: null}
							{loading.loadingError && loading.loadingError.message ? (
								<Grid container item className={localClasses.errorMessage}>
									{loading.loadingError.message}
								</Grid>
							) : null}
						</Grid>
						<div className={localClasses.buttonsSection}>
							<SpinnerButton
								type="submit"
								variant="contained"
								color="primary"
								className={`text-transform`}
								disabled={
									!isSubmitable(
										userRelatedFields || [],
										formValues && LegalInfo,
										filledFields
									) || pristine || !valid
								}
								pathToImagesFolder={`${settings.staticFiles.endpoint}/images`}
								spinnerVisible={loading.formLoading}
							>
								{loading.formLoading
									? t('button.loading')
									: t('button.submit')}
							</SpinnerButton>
							<Button
								type="button"
								variant="outlined"
								color="default"
								onClick={cancel}
								className={`text-transform ${localClasses.buttonMargin}`}
							>
								{t('button.cancel')}
							</Button>
						</div>
					</form>
				)}
			</div>
		</Container>
	);
};

export default reduxForm({
	validate,
	warn,
	form: formId, // a unique identifier for this form
	touchOnChange: true,
	enableReinitialize: true,
	// asyncValidate
})(DynamicForm);
