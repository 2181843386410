import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';
import { LocationApiCountryState, LocationApiCountry, Timezone } from './locationApi';

export interface UserApiModel {
	language?: Language;
	department?: Department;
	state?: LocationApiCountryState;
	country?: LocationApiCountry;
	timezone?: Timezone;
	identities?: Identity[];
	legal_info?: LegalInfo;
	email?: string;
	first_name?: string;
	last_name?: string;
	city?: string;
	zip?: string;
	address?: string;
	phone_number?: string;
	company?: string;
	country_id?: string;
	timezone_id?: string;
	state_id?: string;
	language_id?: string;
	department_id?: string;
}

export interface Identity {
	connection: string;
	metadata: MetaData;
	provider: string;
	user_id: string;
}

interface MetaData {
    LockedProfileFields: string
}

export interface Language {
	id: string;
	text: string;
}

export interface Department {
	id: string;
	text: string;
}

export interface LegalInfo {
	accepted_privacy_policy_version?: string;
	accepted_dip_terms_and_conditions_version?: string;
	accepted_pipl_terms_version?: string;
	application?: TermsAndConditions
}

export interface TermsAndConditions {
	client_id: string;
	accepted_terms_and_conditions_version: string,
	is_privacy_policy_accepted?: boolean
}

export const getUserProfileData = (
	accessToken: string,
	userId: string,
	clientId: string,
	pageLanguage: string
): Promise<UserApiModel> => {

	const requestHeaders = new Headers();
  	requestHeaders.set('Content-Type', 'application/json; charset=utf-8');
  	requestHeaders.append('Authorization', `BEARER ${accessToken}`)

	if (pageLanguage) {
		requestHeaders.append('Accept-Language', pageLanguage)
	}

	return fetchApiWrapper(
		`${settings.backend.myaccount}/users/${userId}?include_legal_info=true&client_id_for_app_legal_info=${clientId}`,
		{
			headers: requestHeaders,
			method: 'GET',
		}
	);
}

export const updateUserProfileData = (
	accessToken: string,
	userId: string,
	user: Partial<UserApiModel>,
	pageLanguage: string
) => {

	const requestHeaders = new Headers();
  	requestHeaders.set('Content-Type', 'application/json; charset=utf-8');
  	requestHeaders.append('Authorization', `BEARER ${accessToken}`)

	if (pageLanguage) {
		requestHeaders.append('Accept-Language', pageLanguage)
	}

	return fetchApiWrapper(`${settings.backend.myaccount}/users/${userId}`, {
		headers: requestHeaders,
		body: JSON.stringify(user),
		method: 'PATCH',
	});
}
